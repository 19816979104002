import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, takeUntil } from 'rxjs';
import { UPUtilityService, ltiParams } from 'up';
import { Product } from 'up/lib/interfaces/product.interface';
import { LtiParamsState } from '../../../shared/store/state/ltiparams.state';
import { Store } from '@ngrx/store';
import { getLtiParams } from '../../../shared/store/selectors/ltiparams.selector';
@Component({
  selector: 'vl-product-launch',
  templateUrl: './product-launch.component.html',
  styleUrls: ['./product-launch.component.scss'],
})
export class ProductLaunchComponent implements OnInit, OnDestroy {
  product!: Product;
  private destroy$ = new Subject<boolean>();
  isProductLoaded: boolean = false;
  THUMBNAIL = 'https://covers.mhedu.com/Jpeg_wide-75/';
  ltiParams$: Observable<ltiParams> = this.store.select(getLtiParams);
  ltiParams!: ltiParams;
  productWarningParam = {
    state: {
      platform: 'Virtual Labs',
    },
  };
  constructor(
    private store: Store<LtiParamsState>,
    private router: Router,
    private upUtilityService: UPUtilityService
  ) {}

  ngOnInit(): void {
    const isVirtualLabLaunchedInNewTab =
      this.upUtilityService.launchAppInNewTab('VL');
    if (isVirtualLabLaunchedInNewTab) {
      isVirtualLabLaunchedInNewTab.location.href =
        location.origin + '/product/modules';
      this.router.navigate(['/product/warning'], this.productWarningParam);
    } else {
      this.getLtiParams();
    }
  }

  loadPairedProduct() {
    this.product = {
      isbn: this.ltiParams?.isbn!,
      author: this.ltiParams?.productAuthor!,
      edition: this.ltiParams?.productEdition!,
      title: this.ltiParams?.productTitle!,
    };
    this.isProductLoaded = true;
  }

  getLtiParams() {
    this.ltiParams$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ltiParams: ltiParams) => {
        this.ltiParams = ltiParams;
        this.loadPairedProduct();
      });
  }

  launchApp() {
    const isVirtualLabLaunchedInNewTab =
      this.upUtilityService.launchAppInNewTab('VL');
    if (isVirtualLabLaunchedInNewTab)
      isVirtualLabLaunchedInNewTab.location.href =
        location.origin + '/product/modules';
    this.router.navigate(['/product/warning'], this.productWarningParam);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
