@if (isProductLoaded) {
  <div class="list-group">
    <div class="list-group-item list-group-item-action product-row">
      <div class="d-flex w-100 justify-content-between">
        <div class="product-row__details">
          <div class="product-row__ebook-image">
            <img
              [src]="THUMBNAIL + product.isbn + '.jpeg'"
              onerror='this.onerror = null; this.src="/assets/Reader_v1.svg"'
              aria-hidden="true"
              [attr.data-automation-id]="'product-image'" />
          </div>
          <div class="product-row__info">
            <div class="product-row__info__text">
              <div
                class="fs-5 fw-semibold"
                [attr.data-automation-id]="'product-title'">
                {{ product.title }}
              </div>
              <p
                class="mb-0 product-row__info__text--author"
                [attr.data-automation-id]="'product-author'">
                {{ product.author }}
              </p>
              <p
                class="mb-0 product-row__info__text--author"
                [attr.data-automation-id]="'product-edition'">
                {{ "INSTRUCTOR.PRODUCT_SELECTION.EDITION" | translate }}:
                {{ product.edition }}
              </p>
              <p
                class="mb-0 product-row__info__text--author"
                [attr.data-automation-id]="'product-isbn'">
                {{ "INSTRUCTOR.PRODUCT_SELECTION.ISBN" | translate }}:
                {{ product.isbn }}
              </p>
            </div>
            <div class="text-end product-row__button-cont">
              <button
                class="btn btn-primary product-row__button-cont--button"
                (click)="launchApp()"
                [attr.data-automation-id]="'select-product-btn'">
                {{
                  "INSTRUCTOR.PRODUCT_SELECTION.LAUNCH_BUTTON_TEXT" | translate
                }}
                <span class="visually-hidden">
                  {{ "FOOTER.OPENS_IN_NEW_WINDOW" | translate }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
